import styled from "styled-components"
import HomeBG from "../images/papaya-salad.jpg"
import borderImg from "../images/border.svg"
import menuBG from "../images/shrimp-rolls.jpg"
import contactBG from "../images/panang.jpg"
import padThaiBG from "../images/padthai.jpg"
import curryBG from "../images/pineapple-curry.jpg"
import drinkBG from "../images/MixedDrink.jpg"
import lunchBG from "../images/fried-fish.jpg"
const SiteContainer = styled.div`
  body,
  html {
    font-family: "Source Sans Pro", sans-serif;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  p,
  a,
  li {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  iframe {
    width: 100%;
    height: 350px;
  }
  button {
    outline: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .container {
    width: 100%;
    max-width: 1000px;
    margin: auto;
  }
  header {
    .logo {
      width: 50%;
      img {
        width: 80px;
      }
    }
    .navbar {
      padding: 10px 1rem 0;
      -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      .container {
        padding-bottom: 0;
        margin-bottom: -15px;
        @media screen and (max-width: 767px) {
          margin-bottom: 15px;
        }
      }
      .navbar-nav {
        margin-top: -14px;
      }
      .nav-item {
        a {
          font-size: 16px;
          font-weight: 400;
          margin: 0 10px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }
  }
  .home-content {
    h3 {
      color: #4f1c0b;
      font-weight: 700;
      letter-spacing: 2px;
      font-size: 40px;
    }
    p {
      font-weight: 400;
    }
    img {
      width: 200px;
      margin: 0 5px;
      border: 1px solid #4f1c0b;
    }
  }
  .top-home {
    background: url(${HomeBG}) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    .cta {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 400px;
      height: 500px;
      text-align: center;
    }
    a {
      button {
        background: #4f1c0b;
        border: 0;
        /* border: 2px solid #fff; */
        color: #fff;
        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 5px 20px;
        &:hover {
          background: #d2ab67;
        }
      }
    }
    img {
      width: 300px;
    }
  }
  section {
    padding: 50px 0;
    &:before {
      background: url(${borderImg}) top left repeat-x;
      content: "";
      display: block;
      width: 100%;
      height: 14px;
      position: absolute;
      left: 0;
      top: -7px;
      background-position: bottom;
      z-index: 20px;
    }
    &:after {
      background: url(${borderImg}) top left repeat-x;
      content: "";
      display: block;
      width: 100%;
      height: 14px;
      position: absolute;
      left: 0;
      bottom: -7px;
      background-position: top;
      z-index: 20px;
    }
    &.no-border {
      &:before,
      &:after {
        background: none;
      }
    }
  }
  .contact-bg {
    background: url(${contactBG}) center center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 400px;
  }

  .menu-bg {
    background: url(${menuBG}) center center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 400px;
  }
  .padthai-bg {
    background: url(${padThaiBG}) center center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 400px;
  }
  .curry-bg {
    background: url(${curryBG}) center center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 400px;
  }
  .drink-bg {
    background: url(${drinkBG}) center center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 400px;
  }
  .lunch-bg {
    background: url(${lunchBG}) center center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 400px;
  }
  .the-menu {
    section:after {
      background: none;
    }
  }
  .menu {
    h2 {
      margin-top: 60px;
      color: #d2ab67;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
      color: #4f1c0b;
      span {
        float: right;
      }
    }
    p {
      font-weight: 400;
      font-size: 15px;
    }
  }
  footer {
    background: #262526;
    padding: 40px 20px 10px;
    p {
      text-align: center;
      color: #919191;
      font-weight: 400;
    }
  }
`

export default SiteContainer
