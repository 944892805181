import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo.png"
const Header = () => (
  <header>
    <nav className="navbar fixed-top navbar-expand-md navbar-light bg-light">
      <div className="container">
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="Thai Favorite" />
          </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="mr-auto navbar-nav">
            <div className="nav-item">
              <a href="/" className="nav-link">
                Home
              </a>
            </div>
            <div className="nav-item">
              <a href="/menu/" className="nav-link">
                Our Menu
              </a>
            </div>
            <div className="nav-item">
              <a href="https://www.thaivillagecuisineunion.com/order" className="nav-link">
                Online Order
              </a>
            </div>
            <div className="nav-item">
              <a href="/contact/" className="nav-link">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
